@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #354052;
  color: #f2f4f5;
}
a, button {
  transition: color 0.3s, background-color 0.3s;
}

a:hover, button:hover {
  color: #8cabdb;
  background-color: #22223b;
}